import React, { useEffect, useState, useContext, useRef} from 'react';　
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import {URLProvider} from "../providers/d3URLProvider";
import {UserProvider} from "../providers/d3UserProvider";
import D3Snackbar from "../components/d3Snackbar";
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import {CircularProgress} from '@mui/material'
import Box from '@mui/material/Box';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Modal from 'react-modal';
import '../../../src/ccs/d3Touroku.css'

const OutputLog = (section) => {
  let d = new Date();
  console.log("TEST："+section+ " @" + d.getMinutes()+"m"+d.getSeconds()+"s."+d.getMilliseconds());
  return
}

//複数選択用のチェックボックス
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


//宛先表示変換Util
//メールアドレスのみ保持しているユーザはメールアドレスのみを出力
//上記以外のユーザは"diplayName <mail>"の形で出力
const adressUtil =(display,mail) => {
  return mail===display?mail:`${display} <${mail}>`;
}

// Grid表示データの要素定義
const columns: GridColDef[] = [
  // GridデータにはユニークIDが必須であるが、カラム要素に追加しないこで表示しないことが可能
  //  { field: 'id', headerName: 'No', width: 20 },
  { field: 'katsudo_mei', headerName: '活動名',       width: 330, align: 'left', headerAlign: 'center'},
  { field: 'event_mei',   headerName: '条件',         width: 180, align: 'left', headerAlign: 'center'},
  { field: 'point_value', headerName: '付与ポイント', width: 180, align: 'center', headerAlign: 'center'},
];

// Girdのヘッダー部用の背景色定義
const GridHeaderStyles = {
 grid: {
   // 罫線
   '.MuiDataGrid-toolbarContainer': {
     borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  
   },
   '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
     borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
   },
   // 列ヘッダに背景色を指定
   '.MuiDataGrid-columnHeaders': {
     backgroundColor: '#65b2c6',
     color: '#fff',
   }
 }
}

// Grid表示データ
const ReceiveTable = ({rows, progress}) =>{
  OutputLog("ReceiveTable")
  const dispGridData = []
  // 取得データをGrid表示用に加工(ユニークIDの付与)
  for (let cnt = 0; cnt < rows.length; cnt++) {
    dispGridData[cnt] = rows[cnt];
    dispGridData[cnt].id = cnt;
  }
  return (
    <Box sx={{ height: 300, width: 708, margin:'auto' }}>
      <DataGrid
        sx={GridHeaderStyles.grid}
        rows={dispGridData}
        columns={columns}
        density='compact'
        pageSizeOptions={[0]} //ページ設定しないので行指定を無効化
        //showColumnRightBorder // 列ヘッダセルの右側に線を引く
        //showCellRightBorder   // セルの右側に線を引く          
        disableRowSelectionOnClick
      />
    </Box>
  );
}

// 活動名表示データ
const ActivityNameText = ({rows, progress, setEventName, setFuyojoken}) =>{
  OutputLog("ActivityNameText")
  if (progress==false) {
    return (
      <TextField
        disabled
        id="eventName"
        label="活動名"
        style={{ width: '250px' , margin:7}}
      />
    );
  } else {
    // 活動名の重複を除きリスト化
    const result = rows.filter((item, index, self) => {
      const nameList = self.map(item => item['katsudo_mei']);
      if (nameList.indexOf(item.katsudo_mei) === index) {
        return item;
      }
    });
    return (
      <Autocomplete
        id="eventName"
        options={result}
        getOptionLabel={(option) => option.katsudo_mei }
        style={{ width: '250px' ,margin:7}}
        renderInput={(params) => <TextField {...params} label="活動名" />}
        onChange={(event, newValue) => {
          if( newValue !== null ) {
            setEventName(newValue.katsudo_mei);
            setFuyojoken("");
          } else {
            setEventName("");
            setFuyojoken("");
          }
        }}
      />
    );
  }
}

// 条件表示データ
const ConditionText = ({rows, progress, setFuyojoken, fuyojoken, eventName}) =>{
  OutputLog("ConditionText")
  var eventList = []
  if( eventName !== "" ) {
    // 選択された活動名に対する条件のみをリスト化
    const callback = item => item.katsudo_mei == eventName; // 検索条件
    eventList = rows.filter(item => callback(item)); 
    if( fuyojoken == "" ) {
      setFuyojoken(eventList[0]);
    }
  }
  
  if(eventName !== "") {
    return (
      <Autocomplete
        id="eventName"
        options={eventList}
        getOptionLabel={(option) => option.event_mei }
        style={{ width: '250px' ,margin:7}}
        renderInput={(params) => <TextField {...params} label="条件" />}
        value={fuyojoken}
        onChange={(event, newValue) => {
          if( newValue !== null ) {
            setFuyojoken(newValue);
          } else {
            setFuyojoken("");
          }
        }}
      />
    );
  } else {
    return (
      <TextField
        disabled
        id="eventName"
        label="条件"
        style={{ width: '250px',margin:7}}
      />
    );
  }
}

// ポイント表示データ
const PointText = ({rows, progress, fuyojoken, eventName }) =>{
  OutputLog("PointText")
  var point = ""
  if( eventName !== "" && fuyojoken !== "" ) {
    point = fuyojoken.point_value
  }
  return (
    <TextField
      disabled
      id="pointText"
      label="付与ポイント"
      value={point}
      style={{margin:7}}
    />
  );
}

// 付与ユーザ表示データ
const UserText = ({progress, sendTo, setSendToUserId}) =>{
  OutputLog("UserText")
  if (progress==false) {
    return (
      <TextField
        disabled
        id="SendTo"
        label="付与ユーザ"
        style={{ width: '480px', margin:7}}
        //margin="dense"
      />
    );
  } else {
    return (
      <Autocomplete
        multiple
        style={{ width: '480px', margin:7}}
        id="SendTo"
        //key={clsKey1}
        options={sendTo}
        getOptionLabel={(option) => option.email }
        //filterSelectedOptions         //選択したもの削除
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
           <li {...props}>
             <Checkbox
               icon={icon}
               checkedIcon={checkedIcon}
            　 //style={{ margin: 5 }}
               checked={selected}
             />
             {option.email}
           </li>
         )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="付与ユーザ"
            //margin="dense"
          />
        )}
        onChange={(_event, newSendToUserId) => {
          setSendToUserId(newSendToUserId.map(f => String(f.user_id)));
        }}
      />
    );
  }
}

//付与日(datepicker)のstyle指定
//const StyledTextField = styled(TextField)({
//  '& input': {margin: 5},});
  


// 付与日表示データ
const DayText = ({progress, setSelectedDate, selectedDate}) =>{
  OutputLog("DayText")
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box sx={{ mx: 0.8 }}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker  
          id="FuyoDay"
          format="YYYY/MM/DD"
          label="付与日"
          defaultValue={dayjs()}
          onChange={(date) => {
            if( date !== null ) {
              //setValue(new Date(today)
              setSelectedDate(date.$d.toLocaleDateString())
            }
          }}
        />
      </DemoContainer>
     </Box>
    </LocalizationProvider>
  );
}




export default function Tags() {
  const ref = useRef();
  const {URLs}= useContext(URLProvider);
  const {loginUserInfo, token, currentUser } = useContext(UserProvider);

  //登録ボタン制御
  const [updating, setUpdating] = React.useState(false);
  //Alert制御
  const [open, setOpen] = React.useState(false);
  const [serversity, setServersity] = React.useState("");
  const [notificationMessage, setNotificationMessage] = React.useState("");

  const [clsKey1, setClsKey1] = useState(true);
  const [clsKey2, setClsKey2] = useState(0);
  
  const [eventName, setEventName] = useState("");    //活動名
  const [fuyojoken, setFuyojoken] = useState("");    //条件（該当レコードを保持）
  const [sendTo, setSendTo]       = useState([]);    //付与先ユーザ
  const [sendToUserId, setSendToUserId] = useState();//操作ユーザ
  const [selectedDate, setSelectedDate] = useState(dayjs().$d.toLocaleDateString());//付与日

  const [rows, setRows] = useState([]);              // マスタ一覧表示用
  const [progress, setProgress] = useState(false);   // マスタ一覧表示用
  
  //ポップアップの登録ボタン制御
  const [updating2, setUpdating2] = useState(false);
  
  const [isModalOpen, setIsModalOpen] = useState(false); //モーダル表示用
  const [katsudo_mei, setKatsudo_mei] = useState('');  //活動名
  const [event_mei, setEvent_mei] = useState('');      //条件
  const [point_value, setPoint_value] = useState('');  //付与ポイント
  const [progress2, setProgress2] = useState(false);
  const [result, setResultRows] = useState([]);
  
  //Alert制御
  const [serversity2, setServersity2] = React.useState("");
  const [notificationMessage2, setNotificationMessage2] = React.useState("");
  const [open2, setOpen2] = React.useState(false);
  
  //初期化
  const [inputValue, setInputValue] = useState('');
  
  //背面制御
  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
    backfaceFixed(true);
  };
  
  //キャンセルボタン押下時の初期化
  const closeModal = () => {
    setIsModalOpen(false);
    setKatsudo_mei('');
    setEvent_mei('');
    setPoint_value('');
    setNotificationMessage2('');
    setServersity2('');
    setOpen2('');
    document.body.style.overflow = "auto";
    backfaceFixed(false);
  };

  const backfaceFixed = (fixed) => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth;
    document.body.style.paddingRight = fixed ? `${scrollbarWidth}px` : '';
  };
  
  //更新ハンドラー
  const handleActivityNameChange = (event) => {
    setKatsudo_mei(event.target.value);
  };
  const handleConditionsChange = (event) => {
    setEvent_mei(event.target.value);
  };
  const handlePointsChange = (event) => {
    setPoint_value(event.target.value);
  };

  
  // 登録ボタン処理
  const btn_FuyoExecution = async(e) => {
    setOpen(false);
    OutputLog("ボタンクリック")
    const fuyo_user_id = sendToUserId
    const tekiyo_ymd = selectedDate
    
    OutputLog("付与対象レコード:")
    console.log(fuyojoken)
    OutputLog("選択ユーザ　　　:"+fuyo_user_id)
    OutputLog("付与日　　　　　:"+tekiyo_ymd)
    
    const isInvalidDate = (date: Date) => Number.isNaN(date.getTime());
    if( fuyojoken == "" || !fuyojoken || fuyo_user_id == "" || fuyo_user_id == undefined || tekiyo_ymd == "" || !tekiyo_ymd || isInvalidDate(new Date(tekiyo_ymd))) {
      //未設定項目あり      
       setServersity("error");
       setNotificationMessage("入力内容が不足しています。");
       setOpen(true);
       return
    }
    setUpdating(true);　//更新開始
    
    const fuyo_point = fuyojoken.point_value
    const point_no = fuyojoken.point_no
    const touroku_user_id = loginUserInfo.user_id
    const koshin_user_id = loginUserInfo.user_id
    OutputLog("ユーザ　　　　　:"+touroku_user_id)
  
     //ポイント付与情報
     const jsonData = JSON.stringify({ fuyo_user_id, fuyo_point, tekiyo_ymd, point_no, touroku_user_id, koshin_user_id});
     console.log(jsonData)
     try {
      OutputLog("登録処理fetch実行")
       const res = await fetch(URLs.URL_PointFuyo, {
         method: 'POST',
         cache: 'no-cache',
         body: jsonData,
         headers: {'Content-Type': 'application/json; charset=utf-8','Authorization': token}})
         if(res.status !== 200 && res.status !== 0) throw new Error();
           OutputLog("登録処理fetch受信")
           setUpdating(false);　//更新中表示終了
           setServersity("success");
           setNotificationMessage("更新が完了しました");
           setOpen(true);
         }
      catch (error) {
       OutputLog("登録処理fetchError")
       console.log(error);
       setUpdating(false);//更新中表示終了
       setServersity("error");
       setNotificationMessage("更新が失敗しました。エラー："+error);
       setOpen(true);
     }
  }
  
  useEffect(() => {
      if(loginUserInfo.user_id!=="") {
        try {
          fetch(URLs.URL_AllUserList, {
            cache: 'no-cache',
            headers: { 'Content-Type': 'application/json; charset=utf-8','Authorization': token }
          })
          .then(result => {
            if(result.status !== 200) throw new Error();
            return result.json()
          })
          .then(data=> {
            setSendTo(data.map(node => ({
              email:adressUtil(node.displayName,node.mail),
              user_id:node.user_id
            })));
          });
        } catch (error) {
          alert("付与ユーザ取得時にエラーが発生しました");
          console.log(error);
        }
      }
  },[loginUserInfo.user_id]);
  
  //活動名取得
  useEffect(() => {
    if( progress == true ) {
      // 取得済みの場合は再取得しない
      return
    }
    if(loginUserInfo.user_id!=="") {
      try {
        fetch(URLs.URL_MasterPointsList, {//→URL_MasterPointsList(D3URLProviderでURL定義)
          cache: 'no-cache',
          headers: { 'Content-Type': 'application/json; charset=utf-8','Authorization': token }
        })
        .then(result => {
          console.log("     .then(result => {");
          if(result.status !== 200) throw new Error();
          return result.json()
        })
        .then(json => {
          console.log("     .then(json => {");
          console.log(json)
          setRows(json.result);
          setProgress(true);
        })
        .then(data=> {
        });
      } catch (error) {
        alert("活動名取得時にエラーが発生しました");
        console.log(error);
        setProgress(false);
      }
    }
  },[loginUserInfo.user_id, progress]);
 
  //活動名リスト取得 
  useEffect(() => {
    if( progress2 == true ) {
      // 取得済みの場合は再取得しない
      return
    }
    
    try {
      fetch(URLs.URL_GetKatsudoMei)
        .then((res) => {
          if (res.status !== 200) {
            alert("ステータスが" + res.status + "です。" + res.message);
            throw new Error();
          }
          return res.json();
        })
        .then((json) => {
          console.log("json1", json);
          setResultRows(json.result);
          setProgress2(true);
        });
    } catch (error) {
      alert('活動名取得時にエラーが発生しました。');
      console.error(error);
      setProgress2(false);
    }
  },[loginUserInfo.user_id, progress2]);

  //登録ボタン押下時
  const sendDataToBackend = async (e) => {
    setUpdating2(true); //更新開始
   
    const user_id = loginUserInfo.user_id;
    const email = currentUser.attributes.email;
    
    const jsonData = '{ "user_id": ' + user_id + ', "currentUser": { "attributes": { "email": "'+ currentUser.attributes.email + '" } },  "katsudo_mei": "' + katsudo_mei + '",  "event_mei": "'+ event_mei +'",  "point_value": ' + point_value + '}'
    console.log(jsonData);
    
    // 入力値のチェック
    if (!katsudo_mei || !event_mei || !point_value) {
      setUpdating2(false); // 更新状態を解除
      setServersity2("error");
      setNotificationMessage2("全ての項目を入力してください");
      setOpen2(true);
      return; // 入力が不足している場合はここで処理を終える
    }
    
    const regex = /^[0-9]*$/;

    if (!regex.test(point_value) || parseInt(point_value) <= 0) {
      setUpdating2(false); // 更新状態を解除
      setServersity2("error");
      setNotificationMessage2("入力値が正しくありません");
      setOpen2(true);
      return; // 数字以外や0以下の値が含まれる場合、ここで処理を終える
    }
    
    try {

      const response = await fetch(URLs.URL_MasterPoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: jsonData,
      });

      
      if (response.status == 200 ) {
        const responseData = await response.json();
        console.log('バックエンドからの応答:', responseData);
        
        if( responseData.statusCode == 200 && responseData.errorCode == 99 ) {
            //console.log('登録重複');
            setUpdating2(false);
            setServersity2("error");
            setNotificationMessage2("登録内容が重複しています");
            setOpen2(true);
          
          } else if (responseData.statusCode == 200 && responseData.errorCode == 0) {
            setUpdating2(false);
            setServersity2("success");
            setNotificationMessage2("更新が完了しました");
            setOpen2(true);
            setUpdating2(false);
            setProgress(false);  // リストを再取得
            setProgress2(false); // リストを再取得
          } else {
            console.error('エラー: HTTPステータス ' + response.status);
            setServersity2("error");
            setNotificationMessage2("管理者以外は登録できません。");
            setOpen2(true);
            setUpdating2(false);
          }
          
          
      } else {
        console.log('400の場合エラーログ')
        console.error('エラー: HTTPステータス ' + response.status);
        setUpdating2(false);
      }
    } catch (error) {
      console.error('エラー:', error);
      setUpdating2(false);
    }
  };


  return (//
    <>

    
    <Box sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
        <ActivityNameText rows={rows} progress={progress} setEventName={setEventName} setFuyojoken={setFuyojoken} />
        <ConditionText rows={rows} progress={progress} setFuyojoken={setFuyojoken} fuyojoken={fuyojoken} eventName={eventName} />
        <PointText rows={rows} progress={progress} fuyojoken={fuyojoken} eventName={eventName} />
    </Box>
    <Box sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
       <UserText progress={progress} sendTo={sendTo} setSendToUserId={setSendToUserId} />
       <DayText progress={progress} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
    </Box>   
    
    <div style={{ display: 'flex', justifyContent: 'Center', margin:5}}>
     <LoadingButton
       variant="contained"
       color="secondary"
       width= "100%"
       flexDirection= "row"
       justifyContent="flex-end"
       //className={center.button}
       onClick={btn_FuyoExecution}
       endIcon={<SendIcon/>}
       loading={updating}
       loadingPosition="start"
       >
       登録
     </LoadingButton>
    </div>  
    <div style={{ display: 'flex', justifyContent: 'Center' }}>
     <Collapse in={open}>
       <Alert sx={{ my: 1, maxWidth: '38ch'}}
         action={
           <IconButton aria-label="close" color="inherit" size="small" onClick={() => {setOpen(false);}}>
             <CloseIcon fontSize="inherit" />
           </IconButton>
         }
         severity={serversity}
       >
        {notificationMessage}
       </Alert>
     </Collapse>
    </div>
    <div style={{ display: 'flex', justifyContent: 'Center', margin:5}}>
      <div><h3>{`●マスタポイント一覧`}</h3></div>
    </div>
    <div>
     <ReceiveTable rows={rows} progress={progress} />
    </div>

    <div style={{ display: 'flex', justifyContent: 'Center', margin:10}}>
     <LoadingButton
       variant="contained"
       color = "warning"
       width= "100%"
       flexDirection= "row"
       justifyContent="flex-end"
       //className={center.button}
       onClick={openModal}
       endIcon={<SendIcon/>}
       //loading={updating}
       loadingPosition="start"
       >
       マスタ登録
     </LoadingButton>
     <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="ポップアップ"
        className="modal"
        //overlayClassName="overlay"
      >
        <div id="modal-content">
            <p style={{ textAlign: 'left' }}>◆マスタ登録</p>
            <Box
              sx={{ '& .MuiTextField-root': { m: 1, width: '20ch' } }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="KatsudouName"
                label="活動名"
                placeholder="プルダウンメニューからの選択又は手入力"
                helperText="リスト選択又は手入力してください。"
                onChange={handleActivityNameChange}
                inputProps={{
                  list: 'katsudoNameList'
                }}
                
              ></TextField>
              <datalist id="katsudoNameList">
                {result.map((row, index) => (
                  <option key={index} value={row.katsudo_mei} />
                ))}
              </datalist>
              <TextField
                id="text"
                label="条件"
                placeholder="条件を入力"
                helperText="条件を入力してください。"
                value={event_mei}
                onChange={handleConditionsChange}
  
                //
              ></TextField>
              <TextField
                id="point"
                label="付与ポイント"
                placeholder="付与ポイントを入力"
                helperText="付与ポイントを入力してください。"
                value={point_value}
                onChange={handlePointsChange}
                //
              ></TextField>
            </Box>
            <Box
            sx={{ '& .MuiTextField-root': { m: 2, width: '35ch' } }}
            noValidate
            autoComplete="off"
            >
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                
                <LoadingButton
                  //onCancel={handleCancel}
                  variant="contained"
                  sx={{ width: 130, mt: 2, mx: 6 }} // 間隔を設定
                  onClick={closeModal}
                
                  
                >
                  キャンセル
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  sx={{ width: 130, mt: 2, mx: 6 }} // 間隔を設定
                  onClick={sendDataToBackend}
                  loading={updating2}
                  loadingPosition="start"
                >
                  登録
                </LoadingButton>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Collapse in={open2}>
                   <Alert sx={{ my: 3, maxWidth: '38ch'}}
                     action={
                       <IconButton aria-label="close" color="inherit" size="small" onClick={() => {setOpen2(false);}}>
                         <CloseIcon fontSize="inherit" />
                       </IconButton>
                     }
                     severity={serversity2}
                   >
                    {notificationMessage2}
                   </Alert>
                 </Collapse>
              </Box>
            </Box>
          </div>
        </Modal>    
    </div>  
     
  </>
    );
}