import React, {useEffect, useState, useContext} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress} from '@mui/material'
import {URLProvider} from "../providers/d3URLProvider";
import {UserProvider} from "../providers/d3UserProvider";
import D3Message from '../components/d3Message';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRowsProp, GridToolbar } from '@mui/x-data-grid';

const rowsTest = [
  // データとしてIDは必須だが、表示しないことは可能
  { id: 1, katsudo_mei: '部内ゼミ', event_mei: '参加者', point_value: '100'},
  { id: 2, katsudo_mei: '部内ゼミ', event_mei: '講演者', point_value: '500'},
  { id: 3, katsudo_mei: '感謝メール', event_mei: '送信者', point_value: '50'},
  { id: 4, katsudo_mei: '感謝メール', event_mei: '受信者', point_value: '100'},
  { id: 5, katsudo_mei: '感謝メール', event_mei: '受信者', point_value: '100'},
  { id: 6, katsudo_mei: '感謝メール', event_mei: '受信者', point_value: '100'},
  { id: 7, katsudo_mei: '感謝メール', event_mei: '受信者', point_value: '100'},
  { id: 8, katsudo_mei: '感謝メール', event_mei: '受信者', point_value: '100'},
  { id: 9, katsudo_mei: '感謝メール', event_mei: '受信者', point_value: '100'},
  { id: 10, katsudo_mei: '感謝メール', event_mei: '受信者', point_value: '100'},
  { id: 11, katsudo_mei: '感謝メール', event_mei: '受信者', point_value: '100'},
  { id: 12, katsudo_mei: '感謝メール', event_mei: '受信者', point_value: '100'},
];

const OutputLog = (section) => {
  let d = new Date();
  console.log("TEST："+section+ " @" + d.getMinutes()+"m"+d.getSeconds()+"s."+d.getMilliseconds());
  return
}

// Grid表示データの要素定義
const columns: GridColDef[] = [
  // GridデータにはユニークIDが必須であるが、カラム要素に追加しないこで表示しないことが可能
  //  { field: 'id', headerName: 'No', width: 20 },
  { field: 'katsudo_mei', headerName: '活動名',       width: 330, align: 'left', headerAlign: 'center'},
  { field: 'event_mei',   headerName: '条件',         width: 180, align: 'left', headerAlign: 'center'},
  { field: 'point_value', headerName: '付与ポイント', width: 180, align: 'center', headerAlign: 'center'},
];

// Girdのヘッダー部用の背景色定義
const GridHeaderStyles = {
 grid: {
   // 罫線
   '.MuiDataGrid-toolbarContainer': {
     borderBottom: 'solid 1px rgba(224, 224, 224, 1)'  
   },
   '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
     borderRight: 'solid 1px rgba(224, 224, 224, 1) !important'
   },
   // 列ヘッダに背景色を指定
   '.MuiDataGrid-columnHeaders': {
     backgroundColor: '#65b2c6',
     color: '#fff',
   }
 }
}

// Grid表示データ
const ReceiveTable = ({rows, progress}) =>{
  if (progress==false) {
    return (
         <CircularProgress />
    );
  } else {
    // 取得データをGrid表示用に加工(ユニークIDの付与)
    const dispGridData = []
    for (let cnt = 0; cnt < rows.length; cnt++) {
      dispGridData[cnt] = rows[cnt];
      dispGridData[cnt].id = cnt;
    }
    return (
      <Box sx={{ height: 300, width: 708, margin:'auto' }}>
        <DataGrid
          sx={GridHeaderStyles.grid}
          rows={dispGridData}
//          rows={rowsTest}
          columns={columns}
          density='compact'
          pageSizeOptions={[0]} //ページ設定しないので行指定を無効化
          //showColumnRightBorder // 列ヘッダセルの右側に線を引く
          //showCellRightBorder   // セルの右側に線を引く          
          disableRowSelectionOnClick
        />
      </Box>
    );
  }
}

export default function CustomizedTables() {
  OutputLog("export default function CustomizedTables() {")
  const {URLs}= useContext(URLProvider);
  const {loginUserInfo, token}= useContext(UserProvider);
  const [rows, setRows] = useState([]);
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    try {
      fetch(URLs.URL_MasterPointsList, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
        ,'Authorization': token
      }
    })
    
    // データ受信後処理
    .then(res => {
      if(res.status!==200) {
        alert("ステータスが"+res.status+"です。" + res.message);
        throw new Error();
      }
      return res.json();
    })
    .then(json => {
      console.log(json)
      setRows(json.result);
      setProgress(true);
    })
    } catch (error) {
      console.log(error);
      alert(error);
      setProgress(false);
    }
  },[]);

  return (
    <div>
     <ReceiveTable rows={rows} progress={progress} />
    </div>
  );
}



