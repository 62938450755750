//* eslint-disable no-use-before-define */
import React, { useEffect, useState, useContext, useRef} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import {URLProvider} from "../providers/d3URLProvider";
import {UserProvider} from "../providers/d3UserProvider";
import D3Snackbar from "../components/d3Snackbar";

const centering = makeStyles((theme) => ({
  center: {
    alignItems: 'center',
    marginTop: 10,
    minWidth: '35ch',
    paddingLeft:10,
    paddingRight:10,
    '& > * + *': {
      marginTop: theme.spacing(10),
    },
  }
}));

//宛先表示変換Util
//メールアドレスのみ保持しているユーザはメールアドレスのみを出力
//上記以外のユーザは"diplayName <mail>"の形で出力
const adressUtil =(display,mail) => {
  return mail===display?mail:`${display} <${mail}>`;
}

//複数選択用のチェックボックス
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const Tag = (props) => {
  const ref = useRef();
  const {URLs}= useContext(URLProvider);
  const {loginUserInfo, token}= useContext(UserProvider);

  const center = centering();
  const [sendTo, setSendTo] = useState([]);
  const [template, setTemplate] = useState([]);
  const [message, setMessage] = useState("");
  const [sendToUserId, setSendToUserId] = useState([]);
  const [clsKey1, setClsKey1] = useState(true);
  const [clsKey2, setClsKey2] = useState(0);
  
  const [updating, setUpdating] = React.useState(false); //メール送信中

  //管理者権限判定 
  useEffect(async () => {
    if(loginUserInfo.user_id!=="") {
      try {
        const user_id = loginUserInfo.user_id;
  
        const jsonData = JSON.stringify({ user_id });
        const res2 = await fetch(URLs.URL_CheckAdministrator, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: jsonData,
        })
        props.setTabSeigyo(false);
        if (res2.status === 200) {
          const responseData = await res2.json();
          if (responseData.result == true) {
            props.setKanrisya(false);
          }
        }
      } catch (error) {
        props.setTabSeigyo(false);
        alert('管理者フラグ取得時にエラーが発生しました');
        console.log(error);
      }
    }
  }, [loginUserInfo.user_id]);

    
  //宛先一覧取得
  //定型分取得
  useEffect(() => {
    if(loginUserInfo.user_id!=="") {
      try {
        fetch(URLs.URL_AllUserList, {
          cache: 'no-cache',
          headers: { 'Content-Type': 'application/json; charset=utf-8','Authorization': token }
        })
        .then(result => {
          if(result.status !== 200) throw new Error();
          return result.json()
        })
        .then(data=> {
          setSendTo(data.map(node => ({
            email:adressUtil(node.displayName,node.mail),
            user_id:node.user_id
          })));
        });
      } catch (error) {
        alert("宛先取得時にエラーが発生しました");
        console.log(error);
      }
      try {
        fetch(URLs.URL_MessageTemplate, {
          cache: 'no-cache',
          headers: { 'Content-Type': 'application/json; charset=utf-8','Authorization': token}
        })
        .then(result => {
          if(result.status !== 200) throw new Error();
          return result.json()
        })
        .then(data => {
          console.log("data",data);
          setTemplate(data.map(node => ({
            title:node.message
          })));
        });
      } catch (error) {
        alert("メッセージテンプレート取得時にエラーが発生しました");
        console.log(error);
      }
    }
  },[clsKey2, loginUserInfo.user_id]);

  //メール送信
  const mailsend = async(e) => {

    setUpdating(true);　   //メール送信中ステータス

    const soshin_user_id = loginUserInfo.user_id;
    const jyushin_user_id = sendToUserId;

    if(jyushin_user_id === "" || message === "") {
      ref.current.d3SnackOpen("E","メール送信時にエラーが発生しました");
      alert("宛先または感謝メッセージが入力されていません");
      setUpdating(false); //送信中表示終了
      return;
    }

    // const message = message;
    const jsonData = JSON.stringify({ soshin_user_id, jyushin_user_id, message });
    console.log("jsonData",jsonData);
    try {
      const res = await fetch(URLs.URL_SendMail, {
        method: 'POST',
        //mode: 'no-cors',
        cache: 'no-cache',
        body: jsonData,
        headers: { 'Content-Type': 'application/json; charset=utf-8','Authorization': token }
      });
      if(res.status !== 200 && res.status !== 0) throw new Error();
      setUpdating(false); //送信中表示終了
      ref.current.d3SnackOpen("S","送信が完了しました");
      setClsKey1(clsKey1?false:true);
      setClsKey2(clsKey2+1);
      setMessage("");
      setSendToUserId("");
     } catch (error) {
      setTemplate([]);
      console.log(error);
      setUpdating(false); //送信中表示終了
      ref.current.d3SnackOpen("E","メール送信時にエラーが発生しました " + error);
      // alert("メール送信時にエラーが発生しました " + error);
    }

    //入力内容クリア
    setMessage("");
    setSendToUserId("");
  }

  return (
    <>
    <div className={center.center}>
      <Autocomplete
        multiple
        id="SendTo"
        key={clsKey1}
        options={sendTo}
        getOptionLabel={(option) => option.email }
        //filterSelectedOptions         //選択したもの削除
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
           <li {...props}>
             <Checkbox
               icon={icon}
               checkedIcon={checkedIcon}
               style={{ marginRight: 8 }}
               checked={selected}
             />
             {option.email}
           </li>
         )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="宛先"
            placeholder="宛先"
          />
        )}
        onChange={(_event, newSendToUserId) => {
          setSendToUserId(newSendToUserId.map(f => String(f.user_id)));
        }}
      />
      <Autocomplete
        freeSolo
        id="Message"
        key={clsKey2}
        options={template.map((option) => option.title)}
        //filterSelectedOptions         //選択したもの削除
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="感謝メッセージ"
            placeholder="感謝メッセージ"
            multiline
          />
        )}
        InputValue={message}
        onInputChange={(_event, newMessage) => {
          setMessage(newMessage);
        }}
      />
      <div>
     <LoadingButton
       variant="contained"
       color="secondary"
       width= "100%"
       flexDirection= "row"
       justifyContent="flex-end"
       className={center.button}
       onClick={mailsend}
       endIcon={<SendIcon/>}
       loading={updating}
       loadingPosition="start"
       >
       送信
     </LoadingButton>
     </div>
    </div>
    <D3Snackbar ref={ref} />
  </>
  );
}
export default Tag;
