import React, {useEffect, useState, useContext} from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {CircularProgress} from '@mui/material'
import {URLProvider} from "../providers/d3URLProvider";
import {UserProvider} from "../providers/d3UserProvider";
import D3Message from '../components/d3Message';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const nameListUtil = (nameList) => {
  if(nameList.length!==0) {
    return `${nameList.join('；')}；`;
  } else {
    return nameList
  }
}

  const ReceiveTable = ({rows, progress}) =>{
    if (progress) {
      return (
         <CircularProgress />
      );
    } else {
      return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>受信日時</StyledTableCell>
                <StyledTableCell align="left">From</StyledTableCell>
                <StyledTableCell align="left">To</StyledTableCell>
                <StyledTableCell align="left">メッセージ内容</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow key={row.soshin_ymd}>
                  <StyledTableCell component="th" scope="row">
                    {row.soshin_ymd}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.soushinUserName}</StyledTableCell>
                  <StyledTableCell style={{ width: 100 }} align="left">{nameListUtil(row.jyushinUserNameList)}</StyledTableCell>
                  <StyledTableCell align="left"><D3Message text={row.message}/></StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  }

export default function CustomizedTables() {
  const {URLs}= useContext(URLProvider);
  const {loginUserInfo, token}= useContext(UserProvider);
  const [rows, setRows] = useState([]);
  const [progress, setProgress] = useState(false);


  useEffect(() => {
    const JushinUser = loginUserInfo.user_id;
    const jsonData2 = JSON.stringify({JushinUser});
    setProgress(true);
    try {
      fetch(URLs.URL_MailHistory, {
      method: 'POST',
      cache: 'no-cache',
      body: jsonData2,
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
        ,'Authorization': token
      }
    })
    //ユーザIDを指定して取得したデータを表示する
    //フィルターかけなくて良い
    .then(res => {
      if(res.status!==200) {
        alert("ステータスが"+res.status+"です。" + res.message);
        throw new Error();
      }
      return res.json();
    })
    .then(json => {
      setRows(json);
      setProgress(false);
    })
    } catch (error) {
      console.log(error);
      alert(error);
      setProgress(false);
    }
  },[]);

  return (
    <ReceiveTable rows={rows} progress={progress} />
  );
}
