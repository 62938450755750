import React,{ createContext, useEffect, useContext } from 'react';
import Amplify, {Auth} from 'aws-amplify';
import awsconfig from '../../aws-exports';
import {URLProvider} from "../providers/d3URLProvider"; //added by Kaing @ 20211221

Amplify.configure(awsconfig);

export const UserProvider = createContext({});
export const D3UserProvider = (props) => {

 const [loginUserInfo, setloginUserInfo] = React.useState({
     mail:"",
     user_id:"",
     sei:"",
     mei:"",
     busho_mei:"",
     group_mei:"",
     team_mei:""
 });
 const [token, setToken] = React.useState();
 const {URLs}= useContext(URLProvider); //Providerから取得
 //20221214追加
 const[currentUser,setCurrentUser]=React.useState();
 
 useEffect(() => {
  const init = async() => {
    //get Login user email address from cognito
    //const currentUser = await Auth.currentAuthenticatedUser();
    //20221214追加
    const currentUser1 = await Auth.currentAuthenticatedUser();
    setCurrentUser(currentUser1);
    
    const test=JSON.stringify({currentUser1});

    setToken(currentUser1.signInUserSession.idToken.jwtToken);

    //get Detailed Login user info from user master DB
    const mail =currentUser1.attributes.email;
    const jsonData = JSON.stringify({mail});
    try {
         const response = await fetch(URLs.URL_GetindividualUserInfo, {
           method: 'POST',
           headers: new Headers({
             'Content-Type': 'application/json'
             ,'Authorization': currentUser1.signInUserSession.idToken.jwtToken
           }),
           body: jsonData
         })
         if (!response.ok) {
           if (response.status === 200) {
              setloginUserInfo(await response.json());
           } else {
             const errorMessage = `Error in fetch ${response.status} (${response.statusText})`
             const error = new Error(errorMessage)
             throw(error)
           }
         }
        setloginUserInfo(await response.json());
    } catch(err) {
         console.error(`Error in fetch: ${err.message}`)
    }
  }
  init()
 }, [URLs.URL_individualUserInfo]);
return(
  <UserProvider.Provider value={{loginUserInfo,token,currentUser}}>
      {props.children}
  </UserProvider.Provider>
)
}
