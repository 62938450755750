import React,{ createContext } from 'react';
export const URLProvider = createContext({});

export const D3URLProvider = (props) => {

 const URLs = {
   URL_GetindividualUserInfo: 'https://ykxdmuibuk.execute-api.ap-northeast-1.amazonaws.com/d3Point-individualuserinfo/d3point-individualuserinfo',
   URL_UpdateIndividualUserInfo: 'https://hosf4yeyhj.execute-api.ap-northeast-1.amazonaws.com/d3Point-IndividualUserInfoUpdate/d3point-individualuserinfoupdate',
   URL_GetBushoInfo : 'https://5r2271x8ma.execute-api.ap-northeast-1.amazonaws.com/d3Point-DepartmentInfoList/d3point-departmentinfolist',
   URL_MailHistory : 'https://km1ss1atn9.execute-api.ap-northeast-1.amazonaws.com/d3Point-mailhistory/d3point-mailhistory',
   URL_SendMail : ' https://vqap89lv09.execute-api.ap-northeast-1.amazonaws.com/d3Point-Sprint2-nodejs/d3point-sendmailwithpoints',
   URL_AllUserList : 'https://qyrv18i1yb.execute-api.ap-northeast-1.amazonaws.com/d3Point-alluserlist/d3point-alluserlist',
   URL_MessageTemplate : 'https://5p9mzl8jk0.execute-api.ap-northeast-1.amazonaws.com/d3Point-MessageTemplate/d3point-messagetemplate',
   URL_PointsHistoryBymenber :' https://vqap89lv09.execute-api.ap-northeast-1.amazonaws.com/d3Point-Sprint2-nodejs/d3point-pointshistorybymenber',
   URL_CheckAdministrator :'https://vqap89lv09.execute-api.ap-northeast-1.amazonaws.com/d3Point-Sprint2-nodejs/d3point-checkadministrator',
   URL_PointsHistoryByAdministrator : 'https://vqap89lv09.execute-api.ap-northeast-1.amazonaws.com/d3Point-Sprint2-nodejs/d3point-pointshistorybyadministrator',
   URL_GetKatsudoMei :'https://tudffbnmm3.execute-api.ap-northeast-1.amazonaws.com/d3Point-getkatsudomei/d3Point-getkatsudomei',
   URL_MasterPointsList : 'https://0d9xwhwd3m.execute-api.ap-northeast-1.amazonaws.com/d3Point-getthemasterpointslist/d3Point-getthemasterpointslist',
   URL_MasterPoint : 'https://rqu1x79j9g.execute-api.ap-northeast-1.amazonaws.com/d3Point-masterpoint/d3Point-masterpoint',
   URL_PointGetTotalPoint :'https://nq0y4e7d5l.execute-api.ap-northeast-1.amazonaws.com/d3Point-gettotalpoint/d3Point-gettotalpoint',
   URL_PointFuyo : 'https://dajmk44wqa.execute-api.ap-northeast-1.amazonaws.com/d3Point-pointfuyo/d3Point-pointfuyo',

 }

 return(
  <URLProvider.Provider value={{URLs}}>
    {props.children}
  </URLProvider.Provider>
 )
}
