import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { URLProvider } from '../providers/d3URLProvider'; //added by Kaing @ 20211221
import { UserProvider } from '../providers/d3UserProvider'; //added by Kaing @ 20211221
import D3Message from '../components/d3Message';

export const D3Shukei = (props) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const { setDisplayFlg } = props;
  const { loginUserInfo, token, currentUser } = useContext(UserProvider);
  const { URLs } = useContext(URLProvider);
  const [
    aggregationTargetPeriodStartDate,
    setAggregationTargetPeriodStartDate,
  ] = useState('');
  const [aggregationTargetPeriodEndDate, setAggregationTargetPeriodEndDate] =
    useState('');
  //TODO:追加となったら前に検索できるものを追加する
  // const [searchKindItem,setSearchKindIten] = useState([{key:"",value:"　"},{key:"hoge",value:"fuga"}]);
  const [searchKindItem, setSearchKindIten] = useState([
    { key: '1', value: '集計期間内の部員毎のポイント合計' },
  ]);
  const [eventItem, setEventItem] = useState([
    {
      master_id: '001',
      katsudo_id: '0000001',
      event_id: '0000001',
      event_mei: '感謝メール送信',
      hyouji_jun: 1,
    },
    {
      master_id: '002',
      katsudo_id: '0000001',
      event_id: '0000002',
      event_mei: '感謝メール受信',
      hyouji_jun: 2,
    },
  ]);
  const [searchKind, setSearchKind] = useState();
  const [master_id, setMaster_id] = useState('');

  const [rows, setRows] = useState([]);
  const [progress, setProgress] = useState(false);
  const [flag, setFlag] = useState(false);
  const [updating, setUpdating] = React.useState(true); //DB更新中

  /*
  useEffect(() => {
    // TODO:ポイント付与イベントのリストを返すサービスが動かない
    // try {
    //   const response = fetch(
    //     'https://c8f3adieag.execute-api.ap-northeast-1.amazonaws.com/d3Point-GetThePointsList/d3point-getthepointslist',
    //     {
    //       method: 'GET',
    //       cache: 'no-cache',
    //       headers: {
    //         'Content-Type': 'application/json; charset=utf-8',
    //         Authorization: token,
    //       },
    //     }
    //   )
    //     .then((res) => {
    //       if (res.status !== 200) throw new Error();
    //       return res.json();
    //     })
    //     .then((json) => {
    //       console.log("json",json)
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  }, []);
  */

  //初期表示
  //検索条件に当日を基準とした場合の期初と期末を設定する
  useEffect(() => {
    const user_id = loginUserInfo.user_id;
    //検索条件
    setJoken();
    searchAdmin();
  }, [loginUserInfo.user_id]);

  //検索初期条件
  function setJoken() {
    const now = new Date();
    const nowMonth = now.getMonth() + 1;

    if ((nowMonth > 3) & (nowMonth < 10)) {
      //4~9月
      setAggregationTargetPeriodStartDate(now.getFullYear() + '/04/01');
      setAggregationTargetPeriodEndDate(now.getFullYear() + '/09/30');
    } else if ((nowMonth > 9) & (nowMonth < 13)) {
      //10~12月
      setAggregationTargetPeriodStartDate(now.getFullYear() + '/10/01');
      setAggregationTargetPeriodEndDate(now.getFullYear() + 1 + '/03/31');
    } else if ((nowMonth > 0) & (nowMonth < 4)) {
      //1~3月
      setAggregationTargetPeriodStartDate(now.getFullYear() - 1 + '/10/01');
      setAggregationTargetPeriodEndDate(now.getFullYear() + '/03/31');
    }
  }

  //検索ボタン押下次処理記載
  //ボタンを押したら、ユーザID、検索条件をバックに渡してデータを取得する
  //アカウント情報を更新
  const searchAdmin = async () => {
    setUpdating(true);

    //更新中ステータス
    const user_id = loginUserInfo.user_id;
    let katsudo_id = '';
    let event_id = '';
    
    if (master_id ==  '001'){
      katsudo_id = '000001';
      event_id = '000001';
    } else if (master_id ==  '002') {
      katsudo_id = '000001';
      event_id = '000002';
    } else {
      katsudo_id = '';
      event_id = '';
    }
    
    const jsonData_admin = JSON.stringify({
      user_id,
      katsudo_id,
      event_id,
      aggregationTargetPeriodStartDate,
      aggregationTargetPeriodEndDate,
      currentUser,
    });

    try {
      const res = await fetch(URLs.URL_PointsHistoryByAdministrator, {
        method: 'POST',
        cache: 'no-cache',
        body: jsonData_admin,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token,
        },
      })
        //ユーザIDを指定して取得したデータを表示する
        //フィルターかけなくて良い
        .then((res) => {
          if (res.status !== 200) {
            alert('ステータスが' + res.status + 'です。' + res.message);

            throw new Error();
          }

          return res.json();
        })
        .then((json) => {
          setRows(json.body.rows);
          setUpdating(false);
          setProgress(false);
        });
    } catch (error) {
      console.log(error);
      alert(error);
      setUpdating(false);
      setProgress(false);
    }
  };

  return (
    <div id="condition">
      <Box
        sx={{ '& .MuiTextField-root': { m: 2, width: '35ch' } }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="from"
          label="集計期間FROM"
          placeholder="YYYY/MM/DDの形式で入力"
          helperText="YYYY/MM/DDの形式で入力してください。"
          onChange={(event) =>
            setAggregationTargetPeriodStartDate(event.target.value)
          }
          value={aggregationTargetPeriodStartDate}
        ></TextField>
        <TextField
          id="to"
          label="集計期間TO"
          placeholder="YYYY/MM/DDの形式で入力"
          helperText="YYYY/MM/DDの形式で入力してください。"
          onChange={(event) =>
            setAggregationTargetPeriodEndDate(event.target.value)
          }
          value={aggregationTargetPeriodEndDate}
        ></TextField>
      </Box>
      <Box sx={{ '& .MuiFormControl-root': { m: 2, width: '35ch' } }}>
        <FormControl>
          <InputLabel id="search-kind-label">検索種別</InputLabel>
          <Select labelId="search-kind-label" id="search-kind" label="検索種別">
            <MenuItem value="">&emsp;</MenuItem>
            {searchKindItem.map((item) => (
              <MenuItem value={item.key}>{item.value}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="event-label">イベント</InputLabel>
          <Select
            labelId="event-label"
            id="event"
            value={master_id}
            onChange={(event) => setMaster_id(event.target.value)}
            label="イベント"
          >
          <MenuItem value="">&emsp;</MenuItem>
            {eventItem.map((eventItem) => (
              <MenuItem value={eventItem.master_id}>
                {eventItem.event_mei}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', m: 2, width: '100%' }}
      >
        <LoadingButton
          variant="contained"
          endIcon={<SendIcon />}
          sx={{ width: 150, mt: 2, mx: 15 }}
          onClick={() => setDisplayFlg(true)}
        >
          画面切替
        </LoadingButton>
        <LoadingButton
          variant="contained"
          endIcon={<SendIcon />}
          onClick={searchAdmin}
          loading={updating}
          sx={{ width: 150, mt: 2, mx: 15 }}
        >
          検索
        </LoadingButton>
      </Box>
      <TableContainer component={Paper}>
        <Table
          align="center"
          sx={{ maxWidth: 800, minWidth: 800 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">ユーザー名</StyledTableCell>
              <StyledTableCell align="center">イベント名</StyledTableCell>
              <StyledTableCell align="center">ポイント合計</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center" component="th" scope="row">
                  <D3Message text={row.sei + ' ' + row.mei} />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <D3Message text={row.katsudo_mei+row.event_mei} />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <D3Message text={row.sum} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
