import React from 'react';
// 以下サインアウト用import
import { AmplifySignOut } from '@aws-amplify/ui-react';

class d3Footer extends React.Component {
  render() {
    return (
      <div>
        <AmplifySignOut
          buttonText="サインアウト" />
      </div>
    );
  }
}

export default d3Footer;
