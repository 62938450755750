import React,{ createContext, useEffect, useContext } from 'react';
import {URLProvider} from "../providers/d3URLProvider"; //added by Kaing @ 20211221
import {UserProvider} from "../providers/d3UserProvider"; //added by matsumoto @ 20220407

export const BushoProvider = createContext({});

export const D3BushoProvider = (props) => {
 const [bushoInfo, setBushoInfo] =React.useState({bushoList:"",groupList:"",teamList:""});
 const {URLs}= useContext(URLProvider); //Providerから取得
 const {loginUserInfo, token}= useContext(UserProvider);//added by matsumoto @ 20220407

 useEffect(() => {
  const init = async() => {
    if(loginUserInfo.mail != ""){
    //get Busho info from user master DB
      try {
        const response = await fetch(URLs.URL_GetBushoInfo, {
          method: 'GET',
          headers: new Headers({'Content-Type': 'application/json','Authorization': token}),//added by matsumoto @ 20220407
        })
        if (!response.ok) {
          if (response.status === 200) {
             setBushoInfo(await response.json());
          } else {
            const errorMessage = `${response.status} (${response.statusText})`
            const error = new Error(errorMessage)
            throw(error)
          }
        }
        setBushoInfo(await response.json());
      } catch(err) {
        console.error(`Error in fetch: ${err.message}`)
      }
    }
  }
  init()
 }, [loginUserInfo.mail]);
 return(
  <BushoProvider.Provider value={{bushoInfo}}>
    {props.children}
  </BushoProvider.Provider>
 )
}
