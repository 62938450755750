//初回レンダリング時ログインされいため、ログインユーザを取得不可。そのため、ログインしてから初回レンダリングを実施する。
import React,{ useState } from "react";
import {D3Shokai} from '../d3Shokai'
import {D3Shukei} from '../d3Shukei'
function WrapD3shokai() {
  const [displayFlg,setDisplayFlg]=useState(true);
  return (
    <>
    {/*管理者の場合は画面切り替えを行えるように制御する*/}
    {displayFlg ? <D3Shokai setDisplayFlg={setDisplayFlg} /> : <D3Shukei setDisplayFlg={setDisplayFlg} />}
    </>
  );
}
export default WrapD3shokai;
