import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

//各タブ画面
import D3thank from './wrapper/Wd3Thank.js';
import D3point from './wrapper/Wd3Point.js';
import D3setting from './wrapper/Wd3Setting.js';
//sprint2小林追加
import D3shokai from './wrapper/Wd3ShokaiTab.js';
//import D3shokai_kanri from './wrapper/Wd3Shokai_kanri.js';
import D3addpoint from './wrapper/Wd3AddPoint.js';
//sprint3 秋田追加


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
 //追記
  const[kanrisya,setKanrisya]=React.useState(true);
  const[tabseigyo,setTabSeigyo]=React.useState(true);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="メール作成" {...a11yProps(0)} disabled={tabseigyo}/>
          <Tab label="メール履歴" {...a11yProps(1)} disabled={tabseigyo}/>
          <Tab label="ポイント照会" {...a11yProps(2)} disabled={tabseigyo}/>
          <Tab label="ポイント付与" {...a11yProps(3)} disabled={kanrisya}/>          
          <Tab label="設定" {...a11yProps(4)} disabled={tabseigyo}/>
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}　>
          <div className="App">
            <D3thank setKanrisya={setKanrisya} setTabSeigyo={setTabSeigyo}/>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className="App">
            <D3point />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div className="App">
            <D3shokai />
          </div>
        </TabPanel>
        
        <TabPanel value={value} index={3} dir={theme.direction}>
          <div className="App">
            <D3addpoint />
          </div>
        </TabPanel>
        
        <TabPanel value={value} index={4} dir={theme.direction}>
          <div className="App">
            <D3setting />
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

export default (FullWidthTabs);
