import React from "react";
import { D3BushoProvider } from '../../providers/d3BushoProvider'; //added by 松本 @ 20220413
import D3setting from '../d3Setting.js';
function WrapD3setting() {
  return (
    <D3BushoProvider>
    <D3setting />
    </D3BushoProvider>
  );
}
export default WrapD3setting;
