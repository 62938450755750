import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { UserProvider } from '../providers/d3UserProvider'; //added by Kaing @ 20211221
import { URLProvider } from '../providers/d3URLProvider'; //added by Kaing @ 20211221
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import D3Message from '../components/d3Message';
import Typography from '@material-ui/core/Typography';
import '../../ccs/d3Shokai.css';

import dayjs from 'dayjs'


export const D3Shokai = (props) => {
  const { setDisplayFlg } = props;

  const customStyles = {
    content: {
      height: '70%',
      top: '55%',
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    backfaceFixed(true);
    document.body.style.overflow = "hidden";
    setIsModalOpen(true);
  };
  const closeModal = () => {
    backfaceFixed(false);
    document.body.style.overflow = "auto";
    setIsModalOpen(false);
  };
  
  const backfaceFixed = (fixed) => {
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.body.style.paddingRight = fixed ? `${scrollbarWidth}px` : '';
  };
  
  const syutokuHani = 10;
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
    const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      padding: 9,
      width: 100,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: 9,
      width: 100,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  //
  const myStyle = {
    '& .MuiInputBase-input': {
      color: '#e33371', // 入力文字の色
    },
    '& label': {
      color: '#e33371', // 通常時のラベル色
    },
    '& .MuiTextField-root': { m: 2, width: '35ch' },
  };

  //Providerから取得
  const { loginUserInfo, token } = useContext(UserProvider);
  const { URLs } = useContext(URLProvider);
  const [aggregationTargetPeriodStartDate,setAggregationTargetPeriodStartDate,] = useState('');
  const [aggregationTargetPeriodEndDate, setAggregationTargetPeriodEndDate] = useState('');
  const [updating, setUpdating] = React.useState(true); //初期処理中orDB検索中（検索ボタン用）
  const [updating2, setUpdating2] = React.useState(true); //初期処理中orDB検索中（上位者表示ボタン用）
  const [updating3, setUpdating3] = React.useState(true); //初期処理中orDB検索中（画面切替ボタン用）
  const [rows, setRows] = useState([]); //検索ボタン用
  const [rows2, setRows2] = useState([]); //上位者表示ボタン用
  const [sum, setSum] = useState('');
  const [progress, setProgress] = useState(false); //検索ボタン用
  const [progress2, setProgress2] = useState(false); //上位者表示ボタン用
  const [kanrisya, setKanrisya] = useState(false);
  
  //20240206小林追記
  const [load, setLoad] = useState(false);

  //初期表示
  //管理者の場合は切り替え画面ボタンを表示
  //検索条件に当日を基準とした場合の期初と期末を設定する
  useEffect(() => {
    const user_id = loginUserInfo.user_id;
    //検索条件
    setJoken();

    //管理者権限判定
    try {
      if (user_id != '') {
        const jsonData3 = JSON.stringify({ user_id });
        const res3 = fetch(URLs.URL_CheckAdministrator, {
          method: 'POST',
          cache: 'no-cache',
          body: jsonData3,
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: token,
          },
        })
          .then((res3) => {
            if (res3.status !== 200) throw new Error();
            return res3.json();
          })
          .then((json) => {
            if (json.result == true) {
              setKanrisya(true);
            }
            initSearch();
            setUpdating3(false);
            setLoad(true);
          });
      }
    } catch (error) {
      alert('管理者フラグ取得時にエラーが発生しました');
      console.log(error);
      setUpdating3(false);
      setLoad(true);
    }
  }, [loginUserInfo.user_id]);

  //検索初期条件
  function setJoken() {
    const now = new Date();
    const nowMonth = now.getMonth() + 1;

    if ((nowMonth > 3) & (nowMonth < 10)) {
      //4~9月
      setAggregationTargetPeriodStartDate(now.getFullYear() + '/04/01');
      setAggregationTargetPeriodEndDate(now.getFullYear() + '/09/30');
    } else if ((nowMonth > 9) & (nowMonth < 13)) {
      //10~12月
      setAggregationTargetPeriodStartDate(now.getFullYear() + '/10/01');
      setAggregationTargetPeriodEndDate(now.getFullYear() + 1 + '/03/31');
    } else if ((nowMonth > 0) & (nowMonth < 4)) {
      //1~3月
      setAggregationTargetPeriodStartDate(now.getFullYear() - 1 + '/10/01');
      setAggregationTargetPeriodEndDate(now.getFullYear() + '/03/31');
    }
    setUpdating2(false);
  }
  //初期表示時処理記載
  //ボタンを押したら、ユーザID、検索条件をバックに渡してデータを取得する
  //アカウント情報を更新
  const initSearch = async () => {
    setUpdating(true);
    
    const now = new Date();
    const nowMonth = now.getMonth() + 1;
    let initTargetPeriodStartDate='';
    let initTargetPeriodEndDate='';
    
    if ((nowMonth > 3) & (nowMonth < 10)) {
      //4~9月
      initTargetPeriodStartDate=`${now.getFullYear()}/04/01`;
      initTargetPeriodEndDate=`${now.getFullYear()}/09/30`;
    } else if ((nowMonth > 9) & (nowMonth < 13)) {
      //10~12月
      initTargetPeriodStartDate=`${now.getFullYear()}/10/01`;
      initTargetPeriodEndDate=`${now.getFullYear() + 1}/03/31`; 
    } else if ((nowMonth > 0) & (nowMonth < 4)) {
      //1~3月
      initTargetPeriodStartDate=`${now.getFullYear() - 1}/10/01`;
      initTargetPeriodEndDate=`${now.getFullYear()}/03/31`;
    }

    //更新中ステータス
    const jsonData = '{"user_id":"'+loginUserInfo.user_id+'","aggregationTargetPeriodStartDate":"'+initTargetPeriodStartDate+'","aggregationTargetPeriodEndDate":"'+initTargetPeriodEndDate+'"}';

    try {
      const res = await fetch(URLs.URL_PointsHistoryBymenber, {
        method: 'POST',
        cache: 'no-cache',
        body: jsonData,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token,
        },
      })
        //取得したデータを表示する
        //フィルターかけなくて良い
        .then((res) => {
          if (res.status !== 200) {
            alert('ステータスが' + res.status + 'です。' + res.message);

            throw new Error();
          }

          return res.json();
        })
        .then((json) => {
          setRows(json.body.rows);
          setSum(json.body.sum);
          setUpdating(false);
          setProgress(false);
        });
    } catch (error) {
      console.log(error);
      alert(error);
      setUpdating(false);
      setProgress(false);
    }
  };



  //検索ボタン押下時処理記載
  //ボタンを押したら、ユーザID、検索条件をバックに渡してデータを取得する
  //アカウント情報を更新
  const search = async () => {
    setUpdating(true);
    //更新中ステータス
    const user_id = loginUserInfo.user_id;
    const jsonData = JSON.stringify({
      user_id,
      aggregationTargetPeriodStartDate,
      aggregationTargetPeriodEndDate,
    });
        console.log(aggregationTargetPeriodStartDate);
        
     //入力チェック
     if(aggregationTargetPeriodStartDate ==''){
       console.log(aggregationTargetPeriodStartDate);
     } 
     if( 
       ((aggregationTargetPeriodStartDate =='') ||( aggregationTargetPeriodStartDate.match(/\d{4}\/\d{2}\/\d{2}/) ) )
      && ((aggregationTargetPeriodEndDate =='') ||( aggregationTargetPeriodEndDate.match(/\d{4}\/\d{2}\/\d{2}/) ) )
      ) {
    try {
      const res = await fetch(URLs.URL_PointsHistoryBymenber, {
        method: 'POST',
        cache: 'no-cache',
        body: jsonData,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token,
        },
      })
        //取得したデータを表示する
        //フィルターかけなくて良い
        .then((res) => {
          if (res.status !== 200) {
            alert('ステータスが' + res.status + 'です。' + res.message);

            throw new Error();
          }

          return res.json();
        })
        .then((json) => {
          setRows(json.body.rows);
          setSum(json.body.sum);
          setUpdating(false);
          setProgress(false);
        });
    } catch (error) {
      console.log(error);
      alert(error);
      setUpdating(false);
      setProgress(false);
    }
    }else {
      alert('日付の形式が違います' );
      setProgress(false);
      setUpdating(false); //送信中表示終了
      return;
      
    }
  };

//検索ボタン用
  const SendTable = ({ rows, progress }) => {
    if (progress) {
      return <CircularProgress />;
    } else {
      return (
        <TableContainer component={Paper} color="primary">
          <Table
            align="center"
            sx={{ maxWidth: 800, minWidth: 800 }}
            aria-label="customized table"
            color="primary"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">ポイント獲得日</StyledTableCell>
                <StyledTableCell align="center">付与ポイント</StyledTableCell>
                <StyledTableCell align="center">活動内容</StyledTableCell>
                <StyledTableCell align="center">イベント</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row,index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row" align="center">
                    <D3Message text={row.tekiyo_ymd} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <D3Message text={row.fuyo_point} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <D3Message text={row.katsudo_mei} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <D3Message text={row.event_mei} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };
  
//上位者表示ボタン押下時処理記載
  const getTopLists = async () => {
    setUpdating2(true);
    //setProgress2(true);
    openModal();
    //更新中ステータス
    //const syutokuHani = 10;

    const jsonData2 = JSON.stringify({
      //user_id,
      aggregationTargetPeriodStartDate,
      aggregationTargetPeriodEndDate,
      syutokuHani,
    });

    try {
      const res2 = await fetch(URLs.URL_PointGetTotalPoint, {
        method: 'POST',
        cache: 'no-cache',
        body: jsonData2,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token,
        },
      })
        //取得したデータを表示する
        //フィルターかけなくて良い
        .then((res2) => {
          if (res2.status !== 200) {
            alert('ステータスが' + res2.status + 'です。' + res2.message);
            throw new Error();
          }
          return res2.json();
        })
        .then((json) => {
          if(json.body.length < syutokuHani){
            for (var number = json.body.length + 1 ; number <= syutokuHani ; number++){
              var paddata = { row_number : number.toString(),busho_mei : '',group_mei : '',team_mei : '',sei:'',mei:'',fuyo_user_id:'',sum:'' };
              json.body.push(paddata);
            }
          }
          
          setRows2(json.body);
          setUpdating2(false);
          setProgress2(false);
        });
    } catch (error) {
      console.log(error);
      alert(error);
      setUpdating2(false);
      setProgress2(false);
    }
  };

//上位者表示ボタン用
  const SendTable2 = ({ rows2, progress2 }) => {
    if (progress2) {
      return <CircularProgress />;
    } else {
      return (
        <TableContainer component={Paper} color="primary">
          <Table
            align="center"
            sx={{ maxWidth: 800, minWidth: 100 }}
            aria-label="customized table"
            color="primary"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell2 align="center">順位</StyledTableCell2>
                <StyledTableCell2 align="center">ユーザー名</StyledTableCell2>
                <StyledTableCell2 align="center">ポイント数</StyledTableCell2>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows2.map((row,index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell2 align="center">
                    <D3Message text={"No." + row.row_number} />
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    <D3Message text={row.sei + ' ' + row.mei} />
                  </StyledTableCell2>
                  <StyledTableCell2 align="center">
                    <D3Message text={row.sum} />
                  </StyledTableCell2>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };


  return (
    <div id="condition">
      <Box
        sx={{ '& .MuiTextField-root': { m: 2, width: '35ch' } }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="from"
          label="集計期間FROM"
          placeholder="YYYY/MM/DDの形式で入力"
          helperText="YYYY/MM/DDの形式で入力してください。"
          onChange={(event) =>
            setAggregationTargetPeriodStartDate(event.target.value)
          }
          value={aggregationTargetPeriodStartDate}
          disabled={!load}
        ></TextField>
        <TextField
          id="to"
          label="集計期間TO"
          placeholder="YYYY/MM/DDの形式で入力"
          helperText="YYYY/MM/DDの形式で入力してください。"
          onChange={(event) =>
            setAggregationTargetPeriodEndDate(event.target.value)
          }
          value={aggregationTargetPeriodEndDate}
          disabled={!load}
          //
        ></TextField>
      </Box>
      <Box
        sx={{ '& .MuiTextField-root': { m: 2, width: '35ch' } }}
        noValidate
        autoComplete="off"
      >
        <LoadingButton
          variant="contained"
          endIcon={<SendIcon />}
          sx={{ width: 150, mt: 2, mb: 2, mx: 6 }}
          onClick={() => setDisplayFlg(false)}
          disabled={!kanrisya}
          helperText="管理者のみ利用可能"
          loading={updating3}
          loadingPosition="start"
        >
          画面切替
        </LoadingButton>

        <LoadingButton
          variant="contained"
          endIcon={<SendIcon />}
          sx={{ width: 150, mt: 2, mb: 2, mx: 6 }}
          onClick={search}
          loading={updating}
          loadingPosition="start"
        >
          検索
        </LoadingButton>
  
        <LoadingButton
          color="secondary"
          variant="contained"
          endIcon={<SendIcon />}
          sx={{ width: 150, mt: 2, mb: 2, mx: 6 }}
          onClick={getTopLists}
          loading={updating2}
          loadingPosition="start"
        >
          上位者表示
        </LoadingButton>
        
        <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="ポップアップ"
        className="modal"
        //overlayClassName="overlay"
        //style={customStyles}
        >
        <h2 align="left">TOP 10 Lists</h2>
        <SendTable2 rows2={rows2} progress2={progress2} />
        <LoadingButton
          variant="contained"
          sx={{ width: 150, mt: 2, mb: 2 }}
          onClick={closeModal}
        >
          閉じる
        </LoadingButton>
        </Modal>
      </Box>
      
      <Box sx={myStyle} noValidate autoComplete="off">
        <Typography variant="h5">有効ポイント</Typography>
        <Typography variant="h4" color="primary">
          {sum}pt
        </Typography>
      </Box>
      <Box
        sx={{ '& .MuiTextField-root': { m: 2, width: '35ch' } }}
        noValidate
        autoComplete="off"
      >
      <SendTable rows={rows} progress={progress} />
      </Box>
    </div>
  );
};
