import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

function D3Snackbar(props, ref) {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("送信が完了しました");

  useImperativeHandle(ref, ()=> ({
    d3SnackOpen: (severity, notification) => {
      setOpen(true);
      setNotificationMessage(notification);
      switch(severity){
        case "E":
          setSeverity("error");
          break;
        case "W":
          setSeverity("warning");
          break;
        case "I":
          setSeverity("info");
          break;
        default:
          setSeverity("success");
      }
  }}));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
    <Snackbar anchorOrigin={{vertical:'top',horizontal:'right'}} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {notificationMessage}
        </Alert>
    </Snackbar>
    </>
  );
}

export default forwardRef(D3Snackbar);