import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import {UserProvider} from "../providers/d3UserProvider"; //added by Kaing @ 20211221
import {BushoProvider} from "../providers/d3BushoProvider"; //added by Kaing @ 20211221
import {URLProvider} from "../providers/d3URLProvider"; //added by Kaing @ 20211221
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {CircularProgress} from '@mui/material'
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export default function MyAccountInfo() {

 //Providerから取得
 const {loginUserInfo, token}= useContext(UserProvider);
 const {bushoInfo}= useContext(BushoProvider);
 const {URLs}= useContext(URLProvider);

 const [mail,setMail] = useState([]);
 const [user_id,setUser_id] = useState([]);
 const [sei,setSei] = useState([]);
 const [mei,setMei] = useState([]);
 const [busho_mei,setBusho_mei] = useState([]);
 const [group_mei,setGroup_mei] = useState([]);
 const [team_mei,setTeam_mei] = useState([]);

 const [loading, setLoading] = React.useState(true); //画面ロード中
 const [updating, setUpdating] = React.useState(false); //DB更新中

 //Alert制御
 const [open, setOpen] = React.useState(false);
 const [serversity, setServersity] = React.useState("");
 const [notificationMessage, setNotificationMessage] = React.useState("");

 //Providerから取得した情報を設定
 useEffect(() => {
    setLoading(true);
    const mail = loginUserInfo.mail;
    const jsonData = JSON.stringify({mail});
    try {
        const res = fetch(URLs.URL_GetindividualUserInfo, {
          method: 'POST',
          body: jsonData,
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: token,
          },
        }).then(result => {
          if(result.status !== 200) throw new Error();
          return result.json();
        }).then(data => {
            setMail(data.mail);
            setUser_id(data.user_id);
            setSei(data.sei);
            setMei(data.mei);
            setBusho_mei(data.busho_mei);
            setGroup_mei(data.group_mei);
            setTeam_mei(data.team_mei);
            setLoading(false);
        });
    } catch (err) {
        console.error(`Error in fetch: ${err.message}`)
        setLoading(false);
    }
 }, []);

 //アカウント情報を更新
 const UpdateloginUserInfo = async() => {
   setUpdating(true);　   //更新中ステータス
   //画面から入力値を取得
   const user_id = document.getElementById("user_id").value;
   const sei = document.getElementById("sei").value;
   const mei = document.getElementById("mei").value;
   //所属名から所属IDを変更
   const busho = (bushoInfo.bushoList !=="") ? bushoInfo.bushoList.filter(function(dept){ return dept.busho_mei === document.getElementById("busho_mei").innerText;}):"";
   const busho_id = busho[0].busho_id;
   const group = bushoInfo.groupList !=="" && bushoInfo.groupList.filter(function(grp){ return grp.group_mei === document.getElementById("group_mei").innerText;})
   const group_id = group[0].group_id;
   const team = bushoInfo.teamList !=="" && bushoInfo.teamList.filter(function(tm){ return tm.team_mei === document.getElementById("team_mei").innerText;})
   const team_id = team[0].team_id;
   //更新アカウント情報
   const jsonData = JSON.stringify({ user_id, sei, mei, busho_id, group_id, team_id});
   try {
     const res = await fetch(URLs.URL_UpdateIndividualUserInfo, {
       method: 'POST',
       cache: 'no-cache',
       body: jsonData,
       headers: {'Content-Type': 'application/json; charset=utf-8','Authorization': token}})
       if(res.status !== 200 && res.status !== 0) throw new Error();
         setUpdating(false);　//更新中表示終了
         setServersity("success");
         setNotificationMessage("更新が完了しました");
         setOpen(true);
         }
    catch (error) {
     console.log(error);
     setUpdating(false);//更新中表示終了
     setServersity("error");
     setNotificationMessage("更新が失敗しました。エラー："+error);
     setOpen(true);
   }
  };

return loading ? (
    <CircularProgress />
    ):(
　<Box sx={{ '& .MuiTextField-root': { m: 1, width: '35ch'} }} noValidate autoComplete="off">
　 <FormControl   sx={{textAlign:"left" }}>
    <TextField
      disabled
      id="mail"
      label="Email"
      placeholder={mail}
      value={mail}
      helperText="Emailはログインユーザーのため変更できません。"
      InputProps={{  readOnly: true }}
    />
    <TextField
      disabled
      id="user_id"
      label="ユーザーID"
      placeholder={user_id.toString()}
      value={user_id}
      helperText="ユーザーIDは自動発番のため変更できません。"
      InputProps={{  readOnly: true }}
    />
    <TextField
      id="sei"
      label="姓"
      value={sei}
      onChange={(event) => {setSei(event.target.value);}}
    />
    <TextField
      id="mei"
      label="名"
      value={mei}
      onChange={(event) => {setMei(event.target.value); }}
    />
    <TextField
      id="busho_mei"
      select
      label="所属部"
      value={busho_mei}
      onChange={(event) => {event.target.value !== undefined && setBusho_mei(event.target.value);}}
    >
    {bushoInfo.bushoList !=="" && bushoInfo.bushoList.map((option) => (
     <MenuItem key={option.busho_id} value={option.busho_mei}>
      {option.busho_mei}
     </MenuItem>
    ))}
    </TextField>
    <TextField
      id="group_mei"
      select
      label="所属グループ"
      value={group_mei}
      onChange={(event) => {event.target.value !== undefined && setGroup_mei(event.target.value);}}
    >
    {bushoInfo.groupList !=="" && bushoInfo.groupList.map((option) => (
      <MenuItem key={option.group_id} value={option.group_mei}>
        {option.group_mei}
      </MenuItem>
    ))}
    </TextField>
    <TextField
      id="team_mei"
      select
      label="所属チーム"
      value={team_mei}
      onChange={(event) => {event.target.value !== undefined && setTeam_mei(event.target.value);}}
    >
    {bushoInfo.teamList !=="" && bushoInfo.teamList.map((option) => (
      <MenuItem key={option.team_id} value={option.team_mei}>
        {option.team_mei}
      </MenuItem>
    ))}
    </TextField>
    <LoadingButton
    onClick={(UpdateloginUserInfo)}
    color="secondary"
    variant="contained"
    endIcon={<SendIcon />}
    sx={{ width: 100,mt:2,mx:15 }}
    loading={updating}
    loadingPosition="start"
    >更新
    </LoadingButton>
    <Collapse in={open}>
      <Alert sx={{ my: 1, maxWidth: '38ch'}}
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={() => {setOpen(false);}}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity={serversity}
      >
       {notificationMessage}
      </Alert>
    </Collapse>
  </FormControl>
 </Box>
 );
}
