//実現方法を確認するためのサンプルです。
import React, {useEffect} from 'react';
import '../ccs/App.css';

import Amplify from "aws-amplify";
import awsconfig from "../aws-exports";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

//タブ
import D3tab from './views/d3Tab.js';
import D3Footer from './views/d3Footer.js'; //added by Kaing @ 20211124
import D3AmplifyAuthenticator from './d3AmplifyAuthenticator';
import { D3URLProvider } from './providers/d3URLProvider'; //added by Kaing @ 20211221
import { D3UserProvider } from './providers/d3UserProvider';


Amplify.configure(awsconfig);

function App() {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

  return authState === AuthState.SignedIn && user ? (
      <D3URLProvider>
        <D3UserProvider>
          <div className="App">
            <D3tab />
            <D3Footer />
          </div>
        </D3UserProvider>
      </D3URLProvider>
      ):(
      <D3URLProvider>
        <D3AmplifyAuthenticator/>
      </D3URLProvider>
  );
}
export default App;
