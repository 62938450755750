import React from 'react';
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyForgotPassword,
  AmplifySignUp,
  AmplifyRequireNewPassword,
} from "@aws-amplify/ui-react";
import Amplify from "aws-amplify";
import awsconfig from "../aws-exports";
import dic from "./Dictionary";

function d3AmplifyAuthenticator(){

    return (
    <AmplifyAuthenticator>

      <AmplifySignIn
        slot="sign-in"
        usernameAlias="email"
        headerText="サインイン画面"
        submitButtonText="サインイン"
        formFields={[
          {
            type: "email",
            label: "メールアドレス *",
            placeholder: "メールアドレスを入力",
            required: true,
          },
          {
            type: "password",
            label: "パスワード *",
            placeholder: "パスワードを入力",
            required: true,
          },
        ]}
      />

      {/* サインアップ画面設定 */}
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        headerText="サインアップ"
        haveAccountText=""
        signInText="サインインに戻る"
        submitButtonText="アカウント作成"
        formFields={[
          {
            type: "email",
            label: "メールアドレス *",
            placeholder: "メールアドレスを入力",
            inputProps: { required: true, autocomplete: "username" },
          },
          {
            type: "password",
            label: "パスワード *",
            placeholder: "パスワードを入力",
            inputProps: { required: false, autocomplete: "new-password" },
          },
        ]}
      />

      <AmplifyRequireNewPassword
        headerText="新しいパスワードを入力"
        submitButtonText="送信"
        slot="require-new-password"
      />

      <AmplifyForgotPassword
        slot="forgot-password"
        headerText="パスワードをリセット"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            label: "メールアドレス",
            placeholder: "メールアドレスを入力",
          },
        ]}
        sendButtonText="送信"
        submitButtonText="送信"
      />
      </AmplifyAuthenticator>
  );
}
export default d3AmplifyAuthenticator;